import {
  ClienteTrocaAvulso,
  Cliente,
  ClienteRecebimento,
  ClienteVenda,
  Produto,
  ClienteAgendamento,
  Bairro,
  ClienteTelefone,
  Funcionario,
  Veiculo,
  CestaVenda,
  Cesta,
  DespesaCombustivelVeiculo,
  ManutencaoVeiculo,
  DespesaRota,
  AdiantamentoFuncionario,
  MeioPagamento,
} from "../../repository/models";
import Moeda from "../../utils/Moeda";
import LocalStorage from "../../utils/localStorage";
import { PrinterLog } from "../../utils/PrinterLog";
import RouteService from "../route";
import { getEnvVar, generateCompanyInvoice, generateCompanyLogo } from "../../service/config";
import { getUser } from "../../service/authentication";
import moment from "moment";
import BusinessError from "../../errorDefinition/BusinessError";
import ApiError from "../../errorDefinition/ApiErrorResponse";
import * as R from "ramda";
import { getClientByHash, getClientLocation } from "../client";
import { validateSchedule } from "../schedulings";
import { version } from "../../../package.json";

const modelMeioPagamento = new MeioPagamento();
const modelCliente = new Cliente();
const modelCesta = new Cesta();
const modelCestaVenda = new CestaVenda();
const modelVeiculo = new Veiculo();
const modelFuncionario = new Funcionario();
const routeService = new RouteService();
const localStorage = LocalStorage.instance;
const modelClienteVenda = new ClienteVenda();
const modelBairro = new Bairro();
const modelClienteTelefone = new ClienteTelefone();
const modelClienteRecebimento = new ClienteRecebimento();
const modelClienteAgendamento = new ClienteAgendamento();
const modelClienteTrocaAvulso = new ClienteTrocaAvulso();
const modelDespesaCombustivelVeiculo = new DespesaCombustivelVeiculo();
const modelManutencaoVeiculo = new ManutencaoVeiculo();
const modelDespesaRota = new DespesaRota();
const modelAdiantamentoFuncionario = new AdiantamentoFuncionario();

export async function fetchMovementsFromServer(clientHash) {
  try {
    const user = await getUser();
    let resp = await fetch(
      `${user.service}/api/rota/clientes/movimentacoes/${clientHash}?token=${user.sessao.token}`,
      { headers: { "Content-Type": "application/json" }, method: "GET" }
    );
    if (!resp.ok) {
      throw new ApiError("Erro ao buscar movimentações do servidor.");
    }
    const result = await resp.json();

    if (!!result.error) {
      throw new ApiError(result.msg);
    }
    return result.movimentacoes;
  } catch (err) {
    return null;
  }
}

/**
 *@description Responsavel por criar registros na tabela de ClienteTrocaAvulso
 *@param {String} hash Hash que identifica o cliente
 *@param {Object} data Informações de troca a serem registradas no banco
 *@param {Date} receivingDate Data escolhida para entrega da troca
 */
export async function createExchange(hash, data, receivingDate) {
  const { error, message } = await validateSchedule({
    dataRecebimento: receivingDate,
  });

  if (error)
    throw new BusinessError({
      message,
      type: "warning",
      title: "Problemas com a data do agendamento",
    });

  let client = await modelCliente.findByHash(hash);

  let route = await localStorage.getItem("route-opening");
  let mainTeam = await localStorage.getItem("main-team");

  if (!route || !mainTeam) {
    throw new BusinessError({
      message: "Abra a rota para prosseguir com esta  ação..",
      title: "Falhar ao realizar troca!",
      type: "warning",
      method: "createExchange",
    });
  }
  data.ativo = true;
  data.ClienteId = client.id;
  data.hashRotaAbertura = route.hash;
  data.RotumId = mainTeam.RotumId;
  data.dataRealizacao = new Date();
  data.shouldSync = true;
  data.hash = String(window.DATABASE.generateUniqId());
  await modelClienteTrocaAvulso.create(data);

  let activeExchange = await modelClienteAgendamento.getAll(
    "ClienteId",
    client.id
  );
  activeExchange = activeExchange.filter(
    (a) => a.tipo.toLowerCase() == "Troca".toLowerCase()
  );

  await Promise.all(
    activeExchange.map(async (el) => {
      el.ativo = false;
      el.shouldSync = true;
      await modelClienteAgendamento.put(el);
    })
  );

  const newScheduling = {
    dataRecebimento: receivingDate,
    dataCriacao: data.dataRealizacao,
    tipo: "Troca",
    editado: false,
    ClienteId: client.id,
    ativo: true,
    hashRotaAbertura: route.hash,
    shouldSync: true,
  };
  client.shouldSync = true;
  await modelClienteAgendamento.create(newScheduling);
  await modelCliente.put(client, false);
  return data.hash;
}

/**
 *@description Recupera movimentações do tipo Recebimento e venda de um cliente num dado intervalo de tempo
 *@param {String} hash Hash que identifica o cliente
 *@param {Date} startDate limite inferior para a pesquisa por data
 *@param {Date} endDate limite superior para a pesquisa por data
 *
 */
export async function fetchMovements(hash, startDate, endDate) {
  if (!startDate || !endDate) {
    startDate = moment("Jan 01 1970 00:00:00 GMT-0300").endOf("day").toDate();

    endDate = moment("Dec 30 3000 00:00:00 GMT-0300").endOf("day").toDate();
  } else {
    startDate = moment(startDate).startOf("day").toDate();
    endDate = moment(endDate).endOf("day").toDate();
  }

  let receive = await modelClienteRecebimento.fetchReceivement(
    hash,
    startDate,
    endDate
  );
  let sale = await modelClienteVenda.fetchSell(hash, startDate, endDate);

  let movements = receive.concat(sale);

  if (movements.length > 0) {
    movements.sort((a, b) => {
      const dateA = a.dataRealizacao;
      const dateB = b.dataRealizacao;
      return dateA - dateB;
    });

    movements = movements.reverse();

    let count = 0;
    let dates = movements.map((el, index) => {
      el.dataRealizacao = moment(el.dataRealizacao);
      if (el.dataRealizacao.isAfter(startDate, "day")) {
        count++;
        return {
          date: el.dataRealizacao.format("DD MMM YYYY"),
          index: index + count,
        };
      }
      return undefined;
    });

    dates.forEach((el) => {
      if (el != undefined) movements.splice(el.index - 1, 0, el.date);
    });
  }
  return movements;
}

const mapperSaleProducts = (sale) => (product) => {
  return {
    CestumId: product.item.id,
    ClienteId: sale.ClienteId,
    ClienteVendaId: sale.id,
    quantidade: product.quantidade,
    shouldSync: true,
    valor: Moeda.create(product.item.valor).format(),
  };
};

export async function createSale(clientHash, saleData) {
  const client = await getClientByHash(clientHash);

  const routeOpening = await routeService.routeOpening();
  const { longitude, latitude } = await getClientLocation();

  if (!client || !routeOpening)
    throw new BusinessError({
      type: "error",
      title: "Houve um problema!",
      method: "service.movements.createSale",
      message: "Sincronize o app e abra a rota para prosseguir",
    });

  const finalValue = Moeda.create(saleData.valor)
    .subtract(saleData.desconto)
    .mount();

  let currentBalance = Moeda.create(client.saldoAtual)
    .subtract(finalValue)
    .mount();

  saleData.ativo = true;
  saleData.shouldSync = true;
  saleData.latitude = latitude;
  saleData.longitude = longitude;
  saleData.ClienteId = client.id;
  saleData.saldo = currentBalance;
  saleData.dataRealizacao = new Date();
  saleData.tipoAtividade = "ClienteVenda";
  saleData.valorFinalDescontado = finalValue;
  saleData.hashRotaAbertura = routeOpening.hash;

  const cestaVendas = saleData.CestaVendas.map(mapperSaleProducts(saleData));

  client.shouldSync = true;
  client.saldoAtual = currentBalance;
  delete saleData.CestaVendas;
  const cestaVendaProms = cestaVendas.map((cestaVenda) =>
    modelCestaVenda.create(cestaVenda)
  );

  await Promise.all([
    modelClienteVenda.create(saleData),
    Promise.all(cestaVendaProms),
    modelCliente.put(client),
  ]);
  return {
    hash: saleData.hash,
    id: saleData.id,
  };
}

export async function generateExchangeInvoice(exchangeHash) {
  const exchange = (
    await modelClienteTrocaAvulso.getAll("hash", [exchangeHash])
  )[0];
  if (!exchange) throw new Error("Troca não encontrada");

  const route = await routeService.mainRoute();
  const companyInvoice = await generateCompanyInvoice();
  const printer = new PrinterLog(32);

  printer.center("Comprovante de troca");
  printer.center(companyInvoice);

  const { dataRealizacao, ClienteId } = exchange;
  await validateAndAppendTimeToInvoice(printer, dataRealizacao);

  await appendClientInvoiceInfo(printer, {
    id: ClienteId,
    routeName: route.nome,
  });

  const { addedProducts, removedProducts } = await getProductsFromExchange(
    exchange
  );
  const sorterByIdentifier = R.sortBy(R.prop("identificador"));
  const stringifyExchangeProducts = (product) =>
    printer.append(`- ${product.identificador}: ${product.quantidade}`);

  printer.append("\n");
  printer.append("Produtos Devolvidos:");
  sorterByIdentifier(removedProducts).forEach(stringifyExchangeProducts);
  printer.append("\n");
  printer.append("Produtos Adicionados:");
  sorterByIdentifier(addedProducts).forEach(stringifyExchangeProducts);

  const viaClient = await appendInvoiceFooter(printer.clone(), true);
  const viaEmployee = await appendInvoiceFooter(printer.clone(), false);

  return {
    viaClient: viaClient.value(),
    viaEmployee: viaEmployee.value(),
  };
}

export async function generateReceivementInvoice(receivementsARG) {
  const printer = new PrinterLog(32);
  const companyLogo = await generateCompanyLogo();
  try {
    const parsedInfo = JSON.parse(receivementsARG);
    const hashArray = [].concat(parsedInfo);

    const receivements = await modelClienteRecebimento.getAll(
      "hash",
      hashArray
    );

    if (!receivements || !receivements.length)
      throw new Error("Recebimento não encontrado");

    const route = await routeService.mainRoute();
    const companyInvoice = await generateCompanyInvoice();
    const companyLogo = await generateCompanyLogo();
    printer.center("Comprovante de Recebimento");
    printer.append("\n");

    printer.center(companyInvoice);
    printer.center(route.telefones.map((el) => el.numero).join(", "));
    printer.append("\n");
    printer.append("\n");
    const { dataRealizacao, ClienteId } = receivements[0];

    await validateAndAppendTimeToInvoice(printer, dataRealizacao);
    await appendClientInvoiceInfo(printer, {
      id: ClienteId,
      routeName: route.nome,
    });

    const linkedSchedule = await getLikedSchedule(receivements);
    const nextReceivementDate = linkedSchedule
      ? moment(linkedSchedule.dataRecebimento).format("DD/MM/YYYY")
      : "Não informado";
    receivements
      .sort((a, b) =>
        a.DATABASE_ID < b.DATABASE_ID
          ? -1
          : a.DATABASE_ID > b.DATABASE_ID
          ? 1
          : 0
      )
      .forEach((receivement) => {
        printer.append("\n");
        printer.append(
          `N° Recibo: ${receivement.documentoFisico || receivement.hash}`
        );
        printer.append(
          `Valor Pago: ${Moeda.create(receivement.valor).format()}`
        );
        printer.append(`MP: ${receivement.meioPagamento}`);
        printer.append(`Saldo devedor: ${receivement.saldo}`);
      });

    printer.append(`Prox. Pagamento: ${nextReceivementDate}`);

    const viaClient = await appendInvoiceFooter(printer.clone(), true);
    const viaEmployee = await appendInvoiceFooter(printer.clone(), false);
    return {
      viaClient: viaClient.value(),
      viaEmployee: viaEmployee.value(),
      imagemLogo: companyLogo
    };
  } catch (error) {
    printer.append("Houve um problema ao recalcular seu comprovante");
    return {
      viaClient: printer.value(),
      viaEmployee: printer.value(),
      imagemLogo: companyLogo
    };
  }
}

async function getLinkedReceivement(sale) {
  return sale.formaPagamento.toLowerCase() !== "parcelado"
    ? (
        await modelClienteRecebimento.getAll("ClienteId", sale.ClienteId)
      ).filter((rec) => rec.ClienteVendaId == sale.id)
    : [];
}

function printReceivementInfo(printer, receivements) {
  receivements.forEach((receivement) => {
    printer.append(
      `N° recibo: ${receivement.documentoFisico || receivement.hash}`
    );
    printer.append(`Valor: ${receivement.valor}`);
    printer.append(`MP: ${receivement.meioPagamento}`);
  });
}

export async function generateSaleInvoice(saleHash) {
  const sale = (await modelClienteVenda.getAll("hash", [saleHash]))[0];
  const printer = new PrinterLog(32);
  const route = await routeService.mainRoute();
  const companyLogo = await generateCompanyLogo();
  try {
    if (!sale) throw new Error("Venda não encontrada");
    const linkedReceivements = await getLinkedReceivement(sale);
    const companyInvoice = await generateCompanyInvoice();
    const allSales = await getOpeningsSales(sale.hashRotaAbertura);
    const saleInfo = allSales.find((el) => el.hash == saleHash);
    const { dataRealizacao, ClienteId, id } = saleInfo;

    const linkedSchedules = await modelClienteAgendamento.getAll("ClienteId", [
      ClienteId,
    ]);
    let nextDate = "Data não encontrada";
    if (linkedSchedules && linkedSchedules.length) {
      const linkedSchedule = linkedSchedules.find(
        (schedule) => schedule.ativo && schedule.ClienteVendaId == id
      );
      if (linkedSchedule)
        nextDate = moment(linkedSchedule.dataRecebimento).format("DD/MM/YYYY");
    }
    const linkedExchanges = await modelClienteTrocaAvulso.getAll(
      "ClienteVendaId",
      [id]
    );
    const exchangesInfo = await Promise.all(
      linkedExchanges.map((exchange) => getProductsFromExchange(exchange))
    );

    const allExchangeInfo = exchangesInfo.reduce(
      (memo, exchange) => {
        const { addedProducts, removedProducts } = exchange;

        memo.addedProducts = memo.addedProducts.concat(addedProducts);
        memo.removedProducts = memo.removedProducts.concat(removedProducts);
        return memo;
      },
      {
        addedProducts: [],
        removedProducts: [],
      }
    ); 

    const exchangeToString = (info) =>
      printer.append(`   - ${info.identificador}: ${info.quantidade}`);  
    printer.center("COMPROVANTE DE VENDA");
    printer.center(companyInvoice);
    printer.center(route.telefones.map((el) => el.numero).join(", "));
    printer.append("\n");
    printer.append("\n");
    await validateAndAppendTimeToInvoice(printer, dataRealizacao);
    await appendClientInvoiceInfo(printer, {
      id: ClienteId,
      routeName: route.nome,
    });
    printer.append(`N° Pedido: ${saleInfo.documentoFisico || saleHash}`);
    printer.append("");
    printer.append("Produtos vendidos:");
    saleInfo.CestaVendas.forEach((cestaVenda) => {
      printer.append(`- ${cestaVenda.cestaNome}: ${cestaVenda.quantidade}`);
    });
    printer.append(`Valor da Venda: ${saleInfo.valor}`);
    printer.append(`Forma de Pagamento: ${saleInfo.formaPagamento}`);
    printReceivementInfo(printer, linkedReceivements);
    printer.append(
      `${
        sale.houveTroca
          ? "Houve troca de produtos"
          : "Não houve troca de produtos"
      }`
    );

    if (sale.houveTroca) {
      printer.append("Trocas Solicitadas");
      printer.append("  Produtos Devolvidos");
      allExchangeInfo.removedProducts.forEach(exchangeToString);
      printer.append("  Produtos Adicionados");
      allExchangeInfo.addedProducts.forEach(exchangeToString);
    }

    const clientSaldo =
      sale.formaPagamento.toLowerCase() !== "parcelado"
        ? linkedReceivements[0].saldo
        : sale.saldo;

    printer.append(`Saldo devedor: ${clientSaldo}`);
    printer.append(`Prox. Pagamento: ${nextDate}`);
    const [viaClient, viaEmployee] = await Promise.all([
      appendInvoiceFooter(printer.clone(), true),
      appendInvoiceFooter(printer.clone(), false),
    ]);

    return {
      viaClient: viaClient.value(),
      viaEmployee: viaEmployee.value(),
      imagemLogo: companyLogo
    };
    
  } catch (error) {
    printer.append("Houve um problema ao recalcular seu comprovante");
    return {
      viaClient: printer.value(),
      viaEmployee: printer.value(),
      imagemLogo: companyLogo
    };
  }
}

async function appendInvoiceFooter(printer, isViaClient) {
  const user = await getUser();
  const rodape = await getEnvVar("EMPRESA_RODAPE");
  printer.append("\n");
  if (isViaClient) {
    printer.center("- Via do Cliente -");
  } else {
    printer.append("\n");
    printer.center(`________________________`);
    printer.center(`Assinatura do Cliente`);
    printer.center("- Via do Vendedor -");
  }

  printer.center("Funcionário: " + user.nome);
  printer.append("\n");

  if (rodape && rodape.trim()) {
    printer.center(rodape);
  }
  printer.center(`-- Versão PAP ${version} --`);
  return printer;
}

async function getLikedSchedule(models) {
  const clientSchedules = await modelClienteAgendamento.getAll("ClienteId", [
    models[0].ClienteId,
  ]);

  const linkedSchedule = clientSchedules.filter((schedule) =>
    models.some(
      (model) => model.id == schedule.ClienteRecebimentoId && schedule.ativo
    )
  );
  return linkedSchedule[0];
}

async function validateAndAppendTimeToInvoice(printer, date) {
  const shouldShowMovTime = await getEnvVar("MOSTRAR_HORA_MOVIMENTACAO");

  if (shouldShowMovTime) {
    printer.append(
      moment(date).format("DD/MM/YYYY") +
        "                 " +
        moment(date).format("HH:mm")
    );
  } else {
    printer.append(moment(date).format("DD/MM/YYYY"));
  }
}

async function getProductsFromExchange(exchange) {
  const allProducts = await new Produto().getAll();

  const mapper = (info) => {
    const product = allProducts.find((p) => p.id == info.ProdutoId);
    return {
      identificador: product ? product.identificador : "Não identificado",
      quantidade: info.quantidade,
    };
  };

  const addedProducts = exchange.adicionados.map(mapper);
  const removedProducts = exchange.retirados.map(mapper);

  return {
    addedProducts,
    removedProducts,
  };
}

async function appendClientInvoiceInfo(printer, clientInfo) {
  const { id, routeName } = clientInfo;
  const client = (await modelCliente.getAll("id", [id]))[0];
  if (!client) throw new Error("Cliente não encontrado");

  const phones = (
    await modelClienteTelefone.getAll("ClienteId", client.id)
  ).filter((p) => p.ativo);
  const neighborghood = await modelBairro.getClientNeighborhood(client.id);

  printer.append(`Rota: ${routeName}`);
  printer.append(`Nome: ${client.nome}`);
  printer.append(
    `${client.logradouro}, ${
      client.numero
        ? client.numero + `, ${neighborghood.nome}`
        : neighborghood.nome
    }`
  );
  printer.append(`${neighborghood.cidade} - ${neighborghood.estado}`);
  if (phones.length) printer.append(`${phones[0].numero}`);
}

export async function generateCloseRouteReport(filledData) {
  const route = await routeService.mainRoute();
  const team = await routeService.team();
  const user = await getUser();

  const {
    hash,
    veiculo,
    cobrador,
    motorista,
    dataAbertura,
    quilometragemVeiculoInicio,
    quilometragemVeiculoFinal,
    dataFechamento,
    palma,
    separar,
  } = filledData || (await routeService.routeOpening());
  const linkedEmployees = await modelFuncionario.getAll("id", [
    motorista,
    cobrador,
  ]);

  const vehicle = (await modelVeiculo.getAll("id", [veiculo]))[0];
  const findEmployee = (id) => (employee) => employee.id == id;

  const companyInvoice = await generateCompanyInvoice();
  const printer = new PrinterLog(32);

  printer.center("FECHAMENTO DE ROTA");
  printer.center(companyInvoice);
  printer.center(route.telefones.map((el) => el.numero).join(", "));
  printer.append(
    "Aberta no dia: " + moment(dataAbertura).format("DD/MM/YYYY [as] HH:mm")
  );
  printer.append("\n");

  if (palma) {
    printer.append(`Abertura para Bater Palma`);
  }
  if (separar) {
    printer.append(`Abertura com divisão de rota`);
  }

  let motoristaToPrint = linkedEmployees.find(findEmployee(motorista));
  let cobradorToPrint = linkedEmployees.find(findEmployee(cobrador));
  printer.center(moment(dataAbertura).format("DD/MM/YYYY"));
  printer.center(`Hash: ${hash}`);
  printer.center(`Rota: ${(route && route.nome) || "---"}`);
  printer.center(`Equipe: ${(team && team.nome) || "---"}`);
  printer.center(
    `Motorista: ${(motoristaToPrint && motoristaToPrint.nome) || "---"}`
  );
  printer.center(
    `Cobrador: ${(cobradorToPrint && cobradorToPrint.nome) || "---"}`
  );
  printer.center(`Funcionario: ${(user && user.nome) || "---"}`);
  printer.center(`Veiculo: ${(vehicle && vehicle.placa) || "---"}`);
  printer.center(`Quilometragem Inicial: ${quilometragemVeiculoInicio}`);
  printer.center(`Quilometragem Final: ${quilometragemVeiculoFinal}`);
  printer.append("\n");

  await appendExchangesRealized(printer, hash);

  await appendSalesInfo(printer, hash);
  printer.append("\n");

  const receivementsSum = await appendReceivementInfo(printer, hash);
  printer.append("\n");

  const expenses = await appendExpensesInfo(printer, { hash });
  printer.append("\n");

  printer.center(`Fechamento Liquído`);

  Object.keys(receivementsSum)
    .sort()
    .forEach((paymentMethod) => {
      if (paymentMethod === "total") return;
      if (paymentMethod === "Dinheiro") {
        printer.center(
          `${paymentMethod}: ${Moeda.create(receivementsSum[paymentMethod])
            .subtract(expenses.total)
            .format()}`
        );
        return;
      }
      printer.center(`${paymentMethod}: ${receivementsSum[paymentMethod]}`);
    });
  printer.center(
    `Fechamento Total: ${Moeda.create(receivementsSum.total)
      .subtract(expenses.total)
      .format()}`
  );
  printer.append("\n");

  printer.center(
    `Fechada no dia ${moment(dataFechamento).format("DD/MM/YYYY")}`
  );
  printer.append("\n");
  printer.append("\n");
  printer.center("____________________________");
  printer.center(`${(motoristaToPrint && motoristaToPrint.nome) || "---"}`);
  printer.append("\n");
  printer.append("\n");
  printer.center("____________________________");
  printer.center(`${(cobradorToPrint && cobradorToPrint.nome) || "---"}`);
  printer.append("\n");
  printer.center(`-- Versão PAP ${version} --`);

  let r = await localStorage.getItem("route-opening");
  r.invoice = printer.value();
  await localStorage.setItem("route-opening", r);

  return printer.value();
}

async function appendExpensesInfo(printer, hashRotaAbertura) {
  const fuel = await modelDespesaCombustivelVeiculo.filterByHashRotaAbertura(
    hashRotaAbertura
  );
  const vehicleMaintenance =
    await modelManutencaoVeiculo.filterByHashRotaAbertura(hashRotaAbertura);
  const routeExpense = await modelDespesaRota.filterByHashRotaAbertura(
    hashRotaAbertura
  );
  const voucher = await modelAdiantamentoFuncionario.filterByHashRotaAbertura(
    hashRotaAbertura
  );
  const somatory = (memo, el) => memo.add(el.valor);

  const sumFuel = fuel.reduce(somatory, new Moeda(0));
  const maintenance = vehicleMaintenance.reduce(somatory, new Moeda(0));
  const routeExpensesSum = routeExpense.reduce(somatory, new Moeda(0));
  const voucherSum = voucher.reduce(somatory, new Moeda(0));
  const Despesas = sumFuel
    .add(maintenance)
    .add(routeExpensesSum)
    .add(voucherSum);

  printer.center(`-Despesas: ${Despesas.format()}`);
  printer.center(`-Combustível: ${sumFuel.format()}`);
  printer.center(`-Manutenção: ${maintenance.format()}`);
  printer.center(`-Demais despesas: ${routeExpensesSum.format()}`);
  const voucherEmployees = voucher.map((vou) => vou.FuncionarioId);
  const employees = await modelFuncionario.getAll("id", [...voucherEmployees]);
  printer.center(`-Vale: ${voucherSum.format()}`);
  for (const vouch of voucher) {
    const employee = employees.find((emp) => emp.id === vouch.FuncionarioId);
    printer.center(
      `${(employee && employee.nome) || "---"}: ${Moeda.create(
        vouch.valor
      ).format()}`
    );
  }

  return {
    sumFuel: sumFuel.mount(),
    maintenance: maintenance.mount(),
    routeExpensesSum: routeExpensesSum.mount(),
    voucherSum: voucherSum.mount(),
    total: Despesas.mount(),
  };
}

async function appendReceivementInfo(printer, hashRotaAbertura) {
  const receivements = await modelClienteRecebimento.getAll(
    "hashRotaAbertura",
    [hashRotaAbertura]
  );
  const allPaymentMethods = (await modelMeioPagamento.getAll())
    .map((meio) => meio.nome)
    .sort();

  const receivementsSum = receivements.reduce((memo, receivement) => {
    const paymentMethod = receivement.meioPagamento;
    const currentSum = memo[paymentMethod];
    const receivementValue = receivement.valor;
    memo[paymentMethod] = Moeda.create(currentSum).add(receivementValue);
    memo.total = Moeda.create(memo.total).add(receivementValue);
    return memo;
  }, {});

  printer.center(
    `Recebimentos: ${Moeda.create(receivementsSum.total).format()}`
  );
  const resultSum = allPaymentMethods.reduce((memo, method) => {
    memo[method] = Moeda.create(receivementsSum[method]).format();
    printer.center(`-${method}: ${memo[method]}`);
    return memo;
  }, {});

  resultSum.total = receivementsSum.total;
  return resultSum;
}

async function appendSalesInfo(printer, hashRotaAbertura) {
  const salesOfOpening = await getOpeningsSales(hashRotaAbertura);
  const cestaVendaArray = salesOfOpening.map((sale) => sale.CestaVendas);
  const allCestaVenda = cestaVendaArray.flat(1);
  const soldBaskets = allCestaVenda.reduce((memo, cestaVenda) => {
    if (!memo[cestaVenda.cestaNome]) memo[cestaVenda.cestaNome] = 0;
    memo[cestaVenda.cestaNome] += cestaVenda.quantidade;
    return memo;
  }, {});

  const keys = Object.keys(soldBaskets);
  printer.append(`Vendas Realizadas: ${salesOfOpening.length}`);
  keys.sort().forEach((cestaName) => {
    printer.center(`-${cestaName}: ${soldBaskets[cestaName]}`);
  });

  return soldBaskets;
}

export async function getOpeningsSales(hashRotaAbertura) {
  const openingSales = await modelClienteVenda.getAll("hashRotaAbertura", [
    hashRotaAbertura,
  ]);
  const cestaVendasProms = openingSales.map((sale) =>
    modelCestaVenda.getAll("ClienteVendaId", [sale.id])
  );
  const cestaVendas = await Promise.all(cestaVendasProms);
  const allBaskets = await modelCesta.getAll();
  const findBasket = (id) => (basket) => basket.id == id;
  const getImportantInfo = (basketSale) => ({
    id: basketSale.id,
    valor: basketSale.valor,
    quantidade: basketSale.quantidade,
    CestumId: basketSale.CestumId,
    cestaNome:
      allBaskets.find(findBasket(basketSale.CestumId))?.nome ||
      "Kit id: " + basketSale.CestumId + ". Valor: " + basketSale.valor,
  });

  openingSales.forEach((sale, index) => {
    const cestaVendasFromSale = cestaVendas[index].map(getImportantInfo);
    sale.CestaVendas = cestaVendasFromSale;
  });

  return openingSales;
}

async function appendExchangesRealized(printer, hashRotaAbertura) {
  const exchangesofOpening = await modelClienteTrocaAvulso.filter(
    (exchange) => exchange.hashRotaAbertura == hashRotaAbertura
  );
  const exchangeSum = exchangesofOpening.reduce((memo, exchange) => {
    if (exchange.dataTrocaRealizada) memo++;
    return memo;
  }, 0);

  printer.append(`Trocas Realizadas: ${exchangeSum}`);
}
