export class PrinterLog {
  constructor(lineLength = 100000000000000000000000000000000000000) {
    this.lineLength = lineLength;
    this.msg = "";
  }

  center(line) {
    let length = line.length;
    length = length % 2 == 0 ? length : length + 1;
    let falta = this.lineLength - length;
    let padLength = parseInt(falta / 2);
    let result = "".padEnd(padLength) + line + "".padEnd(padLength);
    this.append(result);
    return this;
  }

  append(line) {
    this.msg = this.msg.concat(line + "\n");
    return this;
  }

  right(line) {
    let result = line.padStart(this.lineLength);
    this.append(result);
    return this;
  }

  value() {
    return this.msg;
  }

  clone() {
    return new PrinterLog().append(this.msg);
  }
}
