export default class Text2Picture {
  // Function to HTML encode the text
  // This creates a new hidden element,
  // inserts the given text into it
  // and outputs it out as HTML
  static htmlEncode(value) {
    return document.createElement("div").text(value).html();
  }

  static generate(text, lineHeight = 16, returnType = "base64", width = null) {
    return new Promise((resolve) => {
      const padding = 20;
      const fontSize = Text2Picture.findFontSizeByLineHeight(lineHeight);
      const height =
        text.includes && text.includes(";base64,")
          ? 400
          : text.split("\n").length * lineHeight + 2 * padding + 50;
      width = !width
        ? text.includes && text.includes(";base64,")
          ? 300
          : Text2Picture.findWidthByLineHeigth(lineHeight)
        : width;
      const canvasElement = document.createElement("canvas");
      canvasElement.width = width;
      canvasElement.height = height;

      const ctx = canvasElement.getContext("2d");

      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);

      ctx.font = `${fontSize}px Roboto, Helvetica, Arial, sans-serif`;
      ctx.textBaseline = "top";

      if (text.includes && text.includes(";base64,")) {
        const image = document.createElement("img");
        image.setAttribute("src", text);

        ctx.drawImage(
          image,
          (canvasElement.width - image.width) / 2,
          (canvasElement.height - image.height) / 2
        );
      } else {
        ctx.fillStyle = "black";
        ctx.wrapText(text, padding, padding, width - 2 * padding, lineHeight);
      }

      if (returnType == "base64") {
        return resolve(canvasElement.toDataURL());
      }

      canvasElement.toBlob(resolve, "image/png", 1);
    });
  }

  static findFontSizeByLineHeight(lineHeight) {
    return 0.75 * lineHeight;
  }

  static findWidthByLineHeigth(lineHeight) {
    return (220 * lineHeight) / 16;
  }
}
